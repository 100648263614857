<template lang="pug">
  .subcategories
    .subcategories__cont.cont
      ul.subcategories__list
        li.subcategories__item(v-for="(subcategory, i) in subcategories", :key="i")
          router-link.subcategories__link(
            :to="{ name: 'Category', params: { slug: subcategory.slug } }",
            active-class="subcategories__link_active")
            picture.subcategories__picture
              template(v-if="Object.keys(subcategory.image).length")
                source(:srcset="`${subcategory.image.w42} 1x, ${subcategory.image.w84} 1.5x`", type="image/webp")
                source(:srcset="`${subcategory.image.j42} 1x, ${subcategory.image.j84} 1.5x`")
                img.subcategories__image(:src="subcategory.image.j42", :alt="subcategory.name", :title="subcategory.name", loading="lazy", width="100", height="100")
              img.subcategories__image.subcategories__image_stub(v-else, src="/images/logo.svg", :alt="subcategory.name", :title="subcategory.name", loading="lazy", width="100", height="100")
            span.subcategories__name {{ subcategory.name }}
</template>

<script>
import '@/assets/styles/components/subcategories.sass'

export default {
  name: 'TheSubcategories',
  props: {
    subcategories: Array
  }
}
</script>
